
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
	padding: 0;
	margin: 0;
	height: 100%;
	width: 100%;
}